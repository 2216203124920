import React, {
    cloneElement,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { Grid, GridToolbar } from "@progress/kendo-react-grid";
import { ReloadDataContext } from "../../providers/ReloadDataProvider";
import { axiosInstance as axios } from "../../services/axiosInstance";
import { toDataSourceRequestString } from "@progress/kendo-data-query";
import { SearchDebounce } from "../SearchDebounce";
import { ActionButton } from "../Buttons/ActionButton";
import "../../styles/CommonPositionalStyles.scss";
import "../../styles/CommonTextStyles.scss";
import { CenterLoader } from "../Deprecated/CenterLoader.jsx";
import { MultiSelect } from "@progress/kendo-react-dropdowns";

const EDIT_FIELD = "IN_LINE_EDIT";

/**
 * @function DataGrid
 * @description A grid that fetches data from a URI or uses provided data.
 * Provides InLine edit functionality.
 * @param props.useInlineEdit {boolean} - Whether to use InLine edit
 * @param props.showSaveChangesButtonGroup {boolean} - Whether to show the save changes button group
 * @param props.children {Array|Element} - The grid columns
 * @param [props.uri] {string} - The URI to fetch data from, incurs an API call
 * @param [props.data] {Array} - Passed in data to use in the grid
 * @param [props.initialFilter] {Array} - Initial filter to apply to the grid, incurs an API call
 * @param [props.initialSort] {Array} - Initial sort to apply to the grid, incurs an API call
 * @param [props.resizable] {boolean} - Whether the columns are resizable
 * @param [props.searchFields] {Array} - The fields to search, incurs an API call and displayed search textbox
 * @param [props.onSaveChanges] {function} - The function to call when saving changes, must accept reference to the changed entities
 * @param [props.CellRender] {function} - The function to call when rendering a cell
 * @param [props.style] {object} - The style to apply to the grid
 * @param [props.customManualReload] {boolean} - Whether to use a custom manual reload
 * @param [props.disabledPagination] {boolean} - Whether to disable pagination
 * @param [props.others] {object} - Other props to pass to the Grid
 * @return {Grid}
 * @constructor
 */
export const DataGrid = (props) => {
    const {
        useInlineEdit,
        showSaveChangesButtonGroup = true,
        children,
        uri,
        data = [],
        initialFilter = [],
        initialSort = [],
        searchFields = [],
        initialHiddenFields = [],
        onSaveChanges,
        resizable,
        style,
        customManualReload,
        disabledPagination,
        ...others
    } = props;
    const { reloadData, triggerReload } = useContext(ReloadDataContext);
    const [filter, setFilter] = useState(initialFilter);
    const [changesPresent, setChangesPresent] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const updatingEntities = useRef([]);
    const [dataState, setDataState] = useState({
        data: data ?? undefined,
        total: 0,
        skip: 0,
        take: 50,
        sort: initialSort,
        filter: filter,
    });
    const [refreshGridKey, setRefreshGridKey] = useState(0);
    const [hiddenFields, setHiddenFields] = useState(initialHiddenFields);

    // Fetch data from provided URI, or use provided data.
    // If data and URI are provided, combine the two into the grid data.
    useEffect(() => {
        if (!uri) {
            setDataState({
                ...dataState,
                data: data,
                total: data.length,
            });
            return;
        }

        const dataSourceRequest = toDataSourceRequestString({
            skip: dataState.skip,
            take: dataState.take,
            filter: dataState.filter,
            sort: dataState.sort,
        });

        axios.get(uri + dataSourceRequest).then((props) => {
            let dataRes = [...props.data.data, ...data];
            if (useInlineEdit) {
                dataRes = dataRes.map((item) => {
                    item[EDIT_FIELD] = true;
                    return item;
                });
            }

            setDataState({
                ...dataState,
                data: dataRes,
                total: props.data.total,
            });
            setRefreshGridKey((prev) => prev + 1);
        });
    }, [
        reloadData,
        uri,
        dataState.filter,
        dataState.skip,
        dataState.take,
        dataState.sort,
        customManualReload,
    ]);

    // Watch for Filter changes and apply the effect to the dataState
    useEffect(() => {
        setDataState({
            ...dataState,
            skip: 0,
            filter: filter,
        });
    }, [filter]);

    // Changes the page and fetches the data from the API
    const onPageChange = (event) => {
        setDataState({
            ...dataState,
            skip: event.page.skip,
            take: event.page.take,
        });
    };

    const onSortChange = (event) => {
        setDataState({
            ...dataState,
            sort: event.sort,
        });
    };

    // Call the onSaveChanges function and reset the changesPresent state
    // API call is incurred based on the onSaveChanges function
    const saveChanges = () => {
        setLoaderVisible(true);
        onSaveChanges(updatingEntities.current).then((_) => {
            updatingEntities.current = [];
            setChangesPresent(false);
            setLoaderVisible(false);
        });
    };

    const cancelChanges = () => {
        setChangesPresent(false);
        updatingEntities.current = [];
        triggerReload();
        setRefreshGridKey((prev) => prev + 1);
    };

    // Update field value and reflect changes in the grid
    // Does not incur a new API call
    const onItemChange = (e) => {
        if (!useInlineEdit) {
            return;
        }

        let newData = dataState.data.map((item) => {
            if (item.id === e.dataItem.id) {
                const fields = e.field.split(".");
                let current = item;

                for (let i = 0; i < fields.length - 1; i++) {
                    if (!current[fields[i]]) {
                        current[fields[i]] = {};
                    }
                    current = current[fields[i]];
                }

                current[fields[fields.length - 1]] = e.value;

                updatingEntities.current = updatingEntities.current.filter(
                    (entity) => entity.id !== item.id
                );

                updatingEntities.current.push(item);
            }
            return item;
        });

        setChangesPresent(true);
        setDataState((prev) => ({
            ...prev,
            data: newData,
        }));
    };

    const columnOptions = React.Children.toArray(children)
        .filter((x) => x.props.field && x.props.title)
        .map((column) => ({
            field: column.props.field,
            title: column.props.title,
        }));

    const handleColumnVisibilityChange = (event) => {
        setHiddenFields(event.value.map((item) => item.field));
    };

    const ColumnToggle = () => (
        <div className="column-toggle" style={{ marginBottom: "10px" }}>
            <MultiSelect
                data={columnOptions}
                textField="title"
                dataItemKey="field"
                value={columnOptions.filter((col) =>
                    hiddenFields.includes(col.field)
                )}
                onChange={handleColumnVisibilityChange}
                placeholder="Select columns to hide"
                style={{ width: "300px" }}
            />
        </div>
    );

    const visibleColumns = React.Children.toArray(children).filter(
        (column) =>
            !hiddenFields.includes(column.props.field) || !column.props.title
    );

    return (
        dataState.data && (
            <div
                className={"JustifyLeftAlignLeft FlexColumn LargeGap"}
                style={{
                    width: "100%",
                }}
            >
                <div
                    className={
                        "DisplayFlex JustifySpaceBetween AlignItemsStart LargeGap"
                    }
                    style={{
                        width: "100%",
                    }}
                >
                    {searchFields.length > 0 && (
                        <SearchDebounce
                            baseFilter={initialFilter}
                            searchFields={searchFields}
                            setFilter={setFilter}
                            searchTextId={"inLineGridSearch"}
                            toggleSearchId={"gridStateSearchToggle"}
                        />
                    )}
                    <ColumnToggle />
                </div>
                <Grid
                    key={refreshGridKey}
                    data={dataState.data}
                    dataItemKey={"id"}
                    total={dataState.total}
                    skip={dataState.skip}
                    take={dataState.take}
                    onItemChange={onItemChange}
                    editField={EDIT_FIELD}
                    pageable={
                        !disabledPagination && {
                            buttonCount: 3,
                            pageSizes: [25, 50, 100],
                            pageSizeValue: dataState.take,
                        }
                    }
                    sortable={true}
                    sort={dataState.sort}
                    onPageChange={onPageChange}
                    onSortChange={onSortChange}
                    resizable={resizable}
                    style={{ width: "99.99%", ...style }}
                    rowRender={(row, props) => {
                        if (props.dataItem.isActive === false) {
                            return cloneElement(
                                row,
                                {
                                    style: {
                                        backgroundColor:
                                            "rgba(66, 66, 66, 0.3)",
                                    },
                                },
                                row.props.children
                            );
                        } else {
                            return row;
                        }
                    }}
                    {...others}
                >
                    {useInlineEdit && showSaveChangesButtonGroup && (
                        <GridToolbar>
                            <ActionButton
                                permissions={[]}
                                needsConfirmation={true}
                                confirmationText={
                                    "Are you sure you want to save changes?"
                                }
                                theme={"primary"}
                                buttonText={"Save Changes"}
                                type="button"
                                onClick={saveChanges}
                                disabled={!changesPresent}
                            />
                            <ActionButton
                                permissions={[]}
                                theme={"primary"}
                                buttonText={"Cancel Changes"}
                                type="button"
                                onClick={cancelChanges}
                                disabled={!changesPresent}
                            />
                            {loaderVisible && (
                                <span>
                                    <CenterLoader />
                                </span>
                            )}
                        </GridToolbar>
                    )}
                    {visibleColumns}
                </Grid>
            </div>
        )
    );
};
